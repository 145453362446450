import { useMemo } from "react";

import { Typography, theme } from "antd";

const Text = ({
    children,
    size = "",
    type = "",
    color = "",
    bold = false,
    ellipsis = false,
    title,
    style = {},
    paragraph = false,
    ...rest
}) => {
    const { token } = theme.useToken();

    const getSize = useMemo(() => {
        if (size === "xs") return token.fontSize - 4;
        if (size === "sm" || size === "small") return token.fontSize - 2;
        if (size === "base") return token.fontSize;
        if (size === "md" || size === "large") return token.fontSize + 4;
        if (size === "lg") return token.fontSizeHeading2;
        if (size === "xl") return token.fontSizeHeading1;
        if (!!size) return size;
        return token.fontSize;
    }, [size, token]);

    const Component = paragraph ? Typography.Paragraph : Typography.Text;

    return (
        <Component
            style={{
                fontSize: getSize,
                color: color !== "" ? color : false,
                fontWeight: bold ? "bold" : "normal",
                lineHeight: 1.5,
                letterSpacing: -0.01, // -0.01px for better readability
                ...style,
            }}
            ellipsis={ellipsis}
            type={type} //  'secondary' | 'success' | 'warning' | 'danger'
            children={children}
            title={title}
            {...rest}
        />
    );
};

export default Text;
