import ProviderApp from "providers/ProviderApp";
import ProviderProfile from "providers/ProviderProfile";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import AntdProvider from "layout/AntdProvider";
import LayoutBackgroundActions from "layout/LayoutBackgroundActions";
import LayoutDrawer from "layout/LayoutDrawer";
import LayoutIntegrations from "layout/LayoutIntegrations";
import LayoutModal from "layout/LayoutModal";
import LayoutNotifications from "layout/LayoutPopup";
import LayoutRedux from "layout/LayoutRedux";
import Router from "layout/Router";

import "./styles/custom.scss";
import "./utils/i18n";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = `/pdfWorker.mjs`;

function App() {
    return (
        <AntdProvider>
            <LayoutNotifications>
                <LayoutRedux>
                    <ProviderProfile />
                    <ProviderApp />
                    <DndProvider backend={HTML5Backend}>
                        <LayoutModal>
                            <LayoutIntegrations>
                                <LayoutDrawer>
                                    <LayoutBackgroundActions>
                                        <Router />
                                    </LayoutBackgroundActions>
                                </LayoutDrawer>
                            </LayoutIntegrations>
                        </LayoutModal>
                    </DndProvider>
                </LayoutRedux>
            </LayoutNotifications>
        </AntdProvider>
    );
}

export default App;
