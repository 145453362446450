import PropTypes from "prop-types";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";

import { generateHMAC, generateRandomToken } from "helpers/token";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import IconButton from "components/input/IconButton";

import { theme } from "antd";
import { Button } from "antd";

const IntercomChat = ({
    auth: { user, isAuthenticated, loading },
    isFromMenu = false,
}) => {
    if (
        window.location.pathname.startsWith("/viewer") ||
        window.location.pathname.startsWith("/document") ||
        window.location.pathname === "/login-action"
    )
        return null;

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const { t } = useTranslation();

    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
        if (loading) return;

        const APP_ID = process.env.REACT_APP_INTERCOM_ID;
        if (!APP_ID) return;

        if (window.Intercom) window.Intercom("shutdown");

        const intercomScript = `
        var APP_ID = "${APP_ID}";
        (function(){
          var w=window;
          var ic=w.Intercom;
          if(typeof ic==="function"){
            ic('update',w.intercomSettings);
          } else {
            var d=document;
            var i=function(){i.c(arguments);};
            i.q=[];
            i.c=function(args){i.q.push(args);};
            w.Intercom=i;
            var l=function(){
              var s=d.createElement('script');
              s.type='text/javascript';
              s.async=true;
              s.src='https://widget.intercom.io/widget/' + APP_ID;
              var x=d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            };
            if(document.readyState==='complete'){
              l();
            } else if(w.attachEvent){
              w.attachEvent('onload',l);
            } else {
              w.addEventListener('load',l,false);
            }
          }
        })();
      `;

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.innerHTML = intercomScript;

        document.head.appendChild(script);

        const secretKey = process.env.REACT_APP_INTERCOM_SECRET;
        const userId = user ? user._id : generateRandomToken(24);
        const userHash = generateHMAC(secretKey, userId);

        // Identify user
        if (isAuthenticated && user) {
            window.Intercom("boot", {
                app_id: APP_ID,
                email: user.email,
                user_id: user._id,
                user_hash: userHash,
                custom_launcher_selector: "#intercom-btn",
            });
        } else {
            window.Intercom("boot", {
                app_id: APP_ID,
                userHash: localStorage.getItem("intercomToken")
                    ? localStorage.getItem("intercomToken")
                    : userHash,
                custom_launcher_selector: "#intercom-btn",
            });

            // On stock le token pour le réutiliser plus tard
            if (!localStorage.getItem("intercomToken"))
                localStorage.setItem("intercomToken", userHash);
        }

        // Set up listener for unread message count
        window.Intercom("onUnreadCountChange", function (count) {
            setUnreadCount(count);
        });

        return () => {
            document.head.removeChild(script);
        };
    }, [isAuthenticated, user]);

    const unreadCountContent = (
        <div
            className={
                "absolute z-[2] top-[-6px] flex w-[20px] h-[20px] text-2xs text-white bg-red-500 rounded-full" +
                (isAuthenticated && !mdMedia ? " right-0" : " right-[-6px]")
            }
        >
            <div className="m-auto">{unreadCount > 9 ? "9+" : unreadCount}</div>
        </div>
    );

    const { token } = theme.useToken();

    return (
        !loading && (
            <Fragment>
                {isAuthenticated && !mdMedia && isFromMenu ? (
                    <Button
                        id="intercom-btn"
                        icon={<MaterialIcons name="support_agent" />}
                        type="neutral"
                        style={{
                            position: "relative",
                            display: "inline-flex",
                            width: "100%",
                            padding: token.paddingMD,
                            color: token.colorBgBase,
                            marginBottom: token.marginMD,
                            marginTop: token.marginMD,
                        }}
                    >
                        <div>{t("besoinDaide?")}</div>
                        {unreadCount > 0 && unreadCountContent}
                    </Button>
                ) : (
                    (!isAuthenticated || mdMedia) && (
                        <div
                            className="fixed bottom-4 right-4 z-[1]"
                            id="intercom-btn"
                        >
                            <div className="relative">
                                <IconButton icon="chat" />
                                {unreadCount > 0 && unreadCountContent}
                            </div>
                        </div>
                    )
                )}
            </Fragment>
        )
    );
};

IntercomChat.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(IntercomChat);
