import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { prefixLanguages } from "components/integrations/MultiLanguage";

const HomePage = lazy(() => import("components/home/HomePage"));
const PrivateRoute = lazy(() => import("components/routing/PrivateRoute"));
const Login = lazy(() => import("components/user/login/Login"));
const LoginPage = lazy(() => import("components/user/login/LoginPage"));
const RegisterSuccess = lazy(
    () => import("components/register/RegisterSuccess"),
);
const RegisterError = lazy(() => import("components/register/RegisterError"));
const ChangePassword = lazy(() => import("components/user/ChangePassword"));
const ActivateAccount = lazy(() => import("components/user/ActivateAccount"));

const Roadmap = lazy(() => import("components/roadmap/Roadmap"));

const PageNotFound = lazy(() => import("components/utils/PageNotFound"));

const Drive = lazy(() => import("components/drive/Drive"));

const Viewer = lazy(
    () => import(/* webpackPrefetch: true */ "components/viewer/Viewer"),
);

const ViewDocument = lazy(() => import("components/documents/ViewDocument"));
const ViewTender = lazy(() => import("components/call-tender/ViewTender"));

const JoinProject = lazy(() => import("components/project/JoinProject"));

const Profile = lazy(() => import("components/profile/Profile"));

const Account = lazy(() => import("components/account/Account"));

const OAuthCallback = lazy(() => import("components/bcf/OAuthCallback"));

export default function Router() {
    // Routes qui sont accessibles avec un préfix de langue dans l'URL
    const multiLanguagesRoutes = [
        {
            url: "/",
            element: <HomePage />,
        },
        {
            url: "/home",
            element: <HomePage />,
        },
        {
            url: "/roadmap",
            element: <Roadmap />,
        },
    ];

    return (
        <Routes>
            {prefixLanguages.map((language) =>
                multiLanguagesRoutes.map((route, i) => (
                    <Route
                        key={i}
                        exact
                        path={language + route.url}
                        element={route.element}
                    />
                )),
            )}
            <Route exact path="/login-action" element={<Login />} />
            <Route exact path="/login-action/:token" element={<Login />} />
            <Route exact path="/login" element={<LoginPage />} />
            <Route
                exact
                path="/register-success"
                element={<RegisterSuccess />}
            />
            <Route exact path="/register-error" element={<RegisterError />} />
            <Route
                exact
                path="/change-password/:token"
                element={<ChangePassword />}
            />
            <Route
                exact
                path="/activate-account"
                element={<ActivateAccount />}
            />
            <Route
                exact
                path={"/activate-account/:token"}
                element={<ActivateAccount />}
            />

            {["/drive", "/drive/project/:id", "/portal"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive />
                        </PrivateRoute>
                    }
                />
            ))}
            {["/timesheet", "/timesheet/:route"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="timesheet" />
                        </PrivateRoute>
                    }
                />
            ))}

            {["/forms", "/forms/template/:formId", "/forms/:formId"].map(
                (path, i) => (
                    <Route
                        key={i}
                        exact
                        path={path}
                        element={
                            <PrivateRoute>
                                <Drive toolView="forms" />
                            </PrivateRoute>
                        }
                    />
                ),
            )}

            {["/automations", "/automations/:route"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="automations" />
                        </PrivateRoute>
                    }
                />
            ))}
            {["/photos"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="photos" />
                        </PrivateRoute>
                    }
                />
            ))}
            {["/flow"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="flow" />
                        </PrivateRoute>
                    }
                />
            ))}
            {["/recents"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="recents" />
                        </PrivateRoute>
                    }
                />
            ))}
            {["/favorites"].map((path, i) => (
                <Route
                    key={i}
                    exact
                    path={path}
                    element={
                        <PrivateRoute>
                            <Drive toolView="favorites" />
                        </PrivateRoute>
                    }
                />
            ))}
            <Route
                exact
                path="/visas"
                element={
                    <PrivateRoute>
                        <Drive toolView="visas" />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/statistics"
                element={
                    <PrivateRoute>
                        <Drive toolView="statistics" />
                    </PrivateRoute>
                }
            />
            <Route
                exact
                path="/join-project/:id/:token"
                element={<JoinProject />}
            />
            <Route
                exact
                path="/profile"
                element={
                    <PrivateRoute>
                        <Profile />
                    </PrivateRoute>
                }
            ></Route>
            <Route
                exact
                path="/my-account"
                element={
                    <PrivateRoute>
                        <Account />
                    </PrivateRoute>
                }
            ></Route>
            <Route path="/viewer/:id">
                <Route path=":bcfId" element={<Viewer />} />
                <Route path="" element={<Viewer />} />
            </Route>
            <Route path="/viewer-embed/:id">
                <Route path=":bcfId" element={<Viewer isEmbed={true} />} />
                <Route path="" element={<Viewer isEmbed={true} />} />
            </Route>
            <Route path="/viewer-embed-config/:configId/:id">
                <Route path=":bcfId" element={<Viewer isEmbed={true} />} />
                <Route path="" element={<Viewer isEmbed={true} />} />
            </Route>
            <Route path="/document/:id">
                <Route path=":commentId" element={<ViewDocument />} />
                <Route path="" element={<ViewDocument />} />
            </Route>
            <Route path="/tender/:id/:publicToken">
                <Route path="" element={<ViewTender />} />
            </Route>
            <Route
                exact
                path="/bcf-callback"
                element={
                    <PrivateRoute>
                        <OAuthCallback />
                    </PrivateRoute>
                }
            ></Route>
            <Route exact path="*" element={<PageNotFound />} />
        </Routes>
    );
}
