import { useEffect } from "react";
import { useLocation } from "react-router";

const HotjarIntegration = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (
            pathname === "/drive" ||
            pathname.startsWith("/viewer/") ||
            pathname.startsWith("/viewer-embed/") ||
            pathname.startsWith("/viewer-embed-config/")
        ) {
            const script = document.createElement("script");
            script.async = true;
            script.innerHTML = `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:5199584,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
            document.head.appendChild(script);
        }

        return () => {
            const hotjarScript = document.querySelector(
                'script[src^="https://static.hotjar.com"]',
            );
            if (hotjarScript) {
                document.head.removeChild(hotjarScript);
            }
        };
    }, [pathname]);

    // useEffect(() => {
    //     if (cookies?.STATISTICS) {
    //         Hotjar.init(5199584, 6);
    //         // Hotjar.init(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
    //         if (!!user) {
    //             Hotjar.identify(user._id, {
    //                 username: user.firstname + " " + user.lastname,
    //                 email: user.email,
    //             });
    //         }
    //     }
    // }, [cookies, user]);

    return null;
};

export default HotjarIntegration;
